header{
  background-color: #FFFFFF;
  .banner {
    padding: 70px;
    background-color: #000000;
    .banner-logo {
      display: block;
      height: 460px;
      max-height: 100%;
      background: url('/images/SocialisationCanine-FondFonce-RVB.png') 50% no-repeat;
      background-size: contain;
    }
  }
}


.square-accueil{
  .row a.col-xs-4{
    &.pension{
      background: url('/images/ACC_pension.jpg') no-repeat;
    }
    &.toilettage{
      background: url('/images/ACC_toilettage.jpg') no-repeat;
    }
    &.traineau{
      background: url('/images/traineau.jpg') no-repeat;
    }
    &.boutique{
      background: url('/images/ACC_boutique.jpg') no-repeat;
    }
    &.nourriture{
      background: url('/images/ACC_nourriture.jpg') no-repeat;
    }
    &.autres{
      background: url('/images/autres.jpg') no-repeat;
    }
    &.pension,
    &.toilettage,
    &.traineau,
    &.boutique,
    &.nourriture,
    &.autres{
      background-size: cover;
    }
    margin-top: 0;
    margin-bottom: 0;
    box-sizing: border-box;
    padding: 45px;
    height: 376px;
    color: #FFFFFF;
    background-size: cover;
    font-weight:500;
    display: table;
    &:hover,
    &:active{
      text-decoration: none;
    }
    .square-content {
      display: table-cell;
      vertical-align: middle;
      h4 {
        text-align: center;
        font-weight: 700;
      }
      p {
        text-align: justify;
        font-weight: 300;
      }
    }
  }
}


@media (max-width: 768px){
  header{
    min-height:350px;
  }
}
@media (max-width: 480px){
  header{
    min-height:300px;
  }
}
